/* ==========================================================================
   Icon Points
   ========================================================================== */
@layer components {
  .icon-points {
    @apply relative pt-8 m-8 border-t border-gray-300;

    &::before {
      @apply absolute top-0 left-0 w-full h-2 opacity-50;
      @apply bg-gradient-to-b to-white from-gray-300;
      content: "";
    }

    /* ======================================================================
       Element
       ====================================================================== */
    &__headline {
      @apply mb-5 text-2xl font-light text-blue-500;
    }

    &__point {
      @apply flex items-center mb-2;

      &:last-child {
        @apply mb-0;
      }
    }

    &__icon {
      @apply flex-shrink-0 h-8 mr-2;
      width: 2.1875rem;
    }

    &__copy {
      @apply text-lg font-light text-gray-500;
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
  }
}
