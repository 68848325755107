/* ==========================================================================
   Callout Cards
   ========================================================================== */
@layer components {
  .callout-cards {
    @apply relative pt-8 m-8 border-t border-gray-300;

    &::before {
      @apply absolute top-0 left-0 w-full h-2 opacity-50;
      @apply bg-gradient-to-b to-white from-gray-300;
      content: "";
    }

    /* ======================================================================
       Element
       ====================================================================== */
    &__headline {
      @apply mb-5 text-2xl font-light text-blue-500;
    }

    &__row {
      @apply flex flex-col -m-4;

      @screen sm {
        @apply flex-row -m-2;
      }
    }

    &__col {
      @apply flex flex-col p-4;

      &:nth-of-type(3n - 2) {
        .callout-cards__card {
          @apply bg-blue-300;
        }
      }

      &:nth-of-type(3n - 1) {
        .callout-cards__card {
          @apply bg-green-300;
        }
      }

      &:nth-of-type(3n - 3) {
        .callout-cards__card {
          @apply bg-blue-800;
        }
      }

      @screen sm {
        @apply w-1/3 p-2;
      }
    }

    &__card {
      @apply overflow-hidden text-center text-white bg-black rounded-lg;
    }

    &__card-headline {
      @apply m-3 mb-0 text-3xl font-light;

      .numbers {
        @apply font-bold;
      }

      a:hover {
        @apply underline;
      }
    }

    &__card-subheadline {
      @apply m-4 mt-0 text-xl font-light leading-tight;

      @screen sm {
        min-height: 3.125rem;
      }
    }

    &__card-tease-copy {
      @apply mt-4 text-sm text-center text-gray-500;
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
  }
}
