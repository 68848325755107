/* ==========================================================================
   Footer
   ========================================================================== */
@layer components {
  .footer {
    @apply px-8 pt-8 pb-10 text-white bg-blue-500;

    /* ======================================================================
       Element
       ====================================================================== */
    &__nav {
      @apply flex flex-col;

      @screen md {
        @apply flex-row items-center justify-between;
      }
    }

    &__nav-links {
      @apply flex flex-col -m-2;

      @screen md {
        @apply flex-row flex-wrap;
      }
    }

    &__nav-item {
      @apply p-2;

      @screen md {
        &:after {
          @apply ml-2;
          content: "|";
        }

        &:last-child:after {
          @apply hidden;
        }
      }
    }

    &__nav-link {
      @apply font-light;

      &:hover {
        @apply underline;
      }
    }

    &__social {
      @apply flex items-center mt-6;

      @screen md {
        @apply mt-0;
      }
    }

    &__social-headline {
      @apply mr-4 font-bold;

      @screen md {
        @apply mr-6;
      }
    }

    &__disclaimer {
      @apply text-xs;

      p + p {
        @apply mt-4;
      }

      ol,
      ul {
        @apply my-4 list-inside;
      }

      ol {
        @apply list-decimal;
      }

      ul {
        @apply list-disc;
      }

      a:hover {
        @apply underline;
      }
    }

    &__logo-wrapper {
      @apply mt-10;
    }

    &__logo {
      @apply w-full;
      max-width: 360px;

      @screen md {
        @apply ml-auto mr-0;
      }
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
    &__nav {
      &--primary {
        @apply mb-4;

        @screen md {
          @apply mb-1;
        }
      }

      &--secondary {
        @apply mb-8;

        .footer {
          &__nav-links {
            @apply -m-1;
          }

          &__nav-item {
            @apply p-1;

            @screen md {
              &:after {
                @apply ml-1;
              }
            }
          }

          &__nav-link {
            @apply text-xs;
          }
        }
      }
    }
  }
}
