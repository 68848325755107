/* ==========================================================================
   Annotation
   ========================================================================== */
@layer components {
  .annotation {
    @apply flex justify-center;
    @apply text-center rounded-full;
    @apply transition-colors duration-200;
    align-items: safe center;

    /* ======================================================================
       Element
       ====================================================================== */
    &__copy {
      @apply relative z-10 max-h-full overflow-scroll;
      scrollbar-width: none;
      line-height: 1.1;
      font-size: 0.9375rem;

      &::-webkit-scrollbar {
        @apply hidden;
      }
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
    &--colour-blue {
      @apply text-white bg-blue-500;

      &.annotation--link {
        &:hover {
          @apply bg-blue-600;
        }
      }
    }

    &--colour-red {
      @apply text-white bg-red-500;

      &.annotation--link {
        &:hover {
          @apply bg-red-600;
        }
      }
    }

    &--size-xs {
      @apply w-10 h-10;

      .annotation {
        &__copy {
          @apply text-2xl font-medium;
        }
      }
    }

    &--size-sm {
      @apply w-12 h-12;

      .annotation {
        &__copy {
          @apply text-3xl font-medium;
        }
      }
    }

    &--size-md {
      @apply w-40 h-40 px-5 py-7;
    }

    &--size-lg {
      @apply w-48 h-48 px-4 py-8;
    }

    &--arrow {
      &::before {
        @apply absolute w-10 h-10 m-2;
        content: "";
        background-color: inherit;
      }
    }

    &--arrow-top-left {
      &::before {
        @apply top-0 left-0;
        clip-path: polygon(100% 30%, 0 0, 30% 100%);
      }
    }

    &--arrow-top-right {
      &::before {
        @apply top-0 right-0;
        clip-path: polygon(100% 0, 0 30%, 70% 100%);
      }
    }

    &--arrow-bottom-right {
      &::before {
        @apply bottom-0 right-0;
        clip-path: polygon(100% 100%, 70% 0, 0 70%);
      }
    }

    &--arrow-bottom-left {
      &::before {
        @apply bottom-0 left-0;
        clip-path: polygon(0 100%, 100% 70%, 30% 0);
      }
    }
  }
}
