/* ==========================================================================
   Secondary Hero
   ========================================================================== */
@layer components {
  .secondary-hero {
    @apply relative;

    /* ======================================================================
       Element
       ====================================================================== */
    &__copy {
      @apply absolute top-0 left-0 z-20 p-8;
      @apply text-2xl font-light leading-tight text-white;

      @screen md {
        @apply text-3xl py-7;
      }
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
    &--gradient {
      &::after {
        @apply absolute top-0 left-0 z-10 w-full h-32;
        @apply opacity-75 bg-gradient-to-b from-black to-transparent;
        content: "";

        @screen sm {
          @apply h-20;
        }
      }
    }
  }
}
