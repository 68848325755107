/* ==========================================================================
   Sidebar
   ========================================================================== */
@layer components {
  .sidebar {
    /* ======================================================================
       Element
       ====================================================================== */
    &__headline {
      @apply mb-4 text-3xl font-light leading-none text-gray-400;

      @screen md {
        @apply mt-6;
      }
    }

    &__number {
      @apply w-10 h-10 mb-2 text-white bg-black rounded-full;
      @apply inline-flex items-center justify-center;

      &:nth-of-type(3n - 2) {
        @apply bg-blue-800;
      }

      &:nth-of-type(3n - 1) {
        @apply bg-green-300;
      }

      &:nth-of-type(3n - 3) {
        @apply bg-blue-300;
      }
    }

    &__group {
      @apply mb-4;

      &:last-of-type {
        @apply mb-6;
      }
    }

    &__group-link {
      @apply block font-medium text-black;
      @apply transition-colors duration-200;

      &:hover {
        @apply text-blue-500;
      }
    }

    &__group-title {
      @apply font-medium text-black list-none;
      @apply transition-colors duration-200;
      @apply cursor-pointer;

      &::-webkit-details-marker {
        @apply hidden;
      }

      &::after {
        @apply text-red-500;
        content: "+";
      }

      [open] &::after {
        content: "-";
      }

      &:hover {
        @apply text-blue-500;
      }
    }

    &__links {
      @apply mt-1 ml-4;
    }

    &__link {
      @apply font-light text-black;
      @apply transition-colors duration-200;
      font-size: 0.9375rem;

      &:hover {
        @apply text-blue-500 underline;
      }
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
    &__link {
      &--active {
        @apply font-medium;

        [data-theme="dark-blue"] & {
          @apply text-blue-800;
        }

        [data-theme="light-green"] & {
          @apply text-green-400;
        }

        [data-theme="light-blue"] & {
          @apply text-blue-300;
        }
      }
    }
  }
}
