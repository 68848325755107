/* ==========================================================================
   Primary Hero
   ========================================================================== */
@layer components {
  .primary-hero {
    @apply relative;

    /* ======================================================================
       Element
       ====================================================================== */
    &__headline {
      @apply absolute top-0 left-0 max-w-lg p-8;
      @apply text-3xl leading-tight text-white;

      @screen md {
        @apply text-5xl;
      }
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
  }
}
