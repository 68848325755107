/* ==========================================================================
   AdvisorSpeak
   ========================================================================== */
@layer components {
  .advisor-speak {
    /* ======================================================================
       Element
       ====================================================================== */
    &__toggle {
      @apply relative z-20 flex list-none cursor-pointer;

      &::-webkit-details-marker {
        @apply hidden;
      }
    }

    &__headshot {
      @apply absolute top-0 left-0 w-24 h-24 p-2 -ml-3;
      @apply border border-white rounded-full shadow-md;
      @apply transform -translate-y-7;
    }

    &__label {
      @apply py-1 px-4 whitespace-no-wrap shadow-lg;
      @apply text-xl font-normal text-white bg-blue-500 rounded-xl;
      @apply transition-colors duration-200;
      min-width: 230px;

      &:hover {
        @apply bg-blue-600;
      }
    }

    &__copy {
      @apply absolute z-10 p-4 -mt-4;
      @apply font-light text-gray-500;
      @apply bg-white shadow-md pt-14 rounded-bl-md rounded-br-md;

      [open] & {
        @apply animate-fade-down;
      }
    }

    &__copy-row {
      @apply pb-2 mb-2 text-sm leading-tight border-b border-blue-500;

      &:last-child {
        @apply pb-0 mb-0 text-base border-b-0;
      }

      p + p {
        @apply mt-3;
      }

      a {
        @apply font-normal text-blue-500;
        @apply transition-colors duration-200;

        &:hover {
          @apply text-blue-800 underline;
        }
      }
    }

    &__name {
      @apply mb-2 text-lg font-bold;
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
    &--has-headshot {
      .advisor-speak {
        &__label {
          @apply pl-24;
        }
      }
    }
  }
}
