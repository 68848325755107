/* ==========================================================================
   Annotated Asset
   ========================================================================== */
@layer components {
  .annotated-asset {
    @apply relative m-8;

    @screen lg {
      @apply mx-auto;
    }

    /* ======================================================================
       Element
       ====================================================================== */
    &__pin {
      @apply absolute z-20 transform -translate-x-1/2 -translate-y-1/2;

      .is-blurred & {
        @apply z-auto;
      }
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
    &__pin {
      &--advisorspeak {
        &,
        .is-blurred & {
          @apply z-40;
        }
      }
    }
  }
}
