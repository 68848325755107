/* ==========================================================================
   Launch Pad
   ========================================================================== */
@layer components {
  .launch-pad {
    @apply flex flex-col items-center justify-center;
    @apply text-white bg-red-500 rounded-full;
    @apply transition-colors duration-300 ease-in-out;
    width: 9.75rem;
    height: 9.75rem;

    &:hover {
      @apply bg-blue-500;
    }

    /* ======================================================================
       Element
       ====================================================================== */
    &__rocket {
      width: 100%;
      max-width: 3.25rem;
    }

    &__copy {
      @apply mx-6 mt-2 mb-2 text-lg font-bold leading-none text-center;
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
  }
}
