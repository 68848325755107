/* ==========================================================================
   Logo
   ========================================================================== */
@layer components {
  .logo {
    /* ======================================================================
       Element
       ====================================================================== */
    &__type,
    &__outer-mark,
    &__inner-mark,
    &__circle-r {
      @apply fill-current;
    }

    &__outer-mark {
      @apply text-red-500;
    }

    &__inner-mark {
      @apply text-white;
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
    &--on-white {
      .logo {
        &__type,
        &__circle-r {
          @apply text-blue-500;
        }
      }
    }

    &--on-colour {
      .logo {
        &__type,
        &__cricle-r {
          @apply text-white;
        }
      }
    }
  }
}
