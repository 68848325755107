/* ==========================================================================
   Table of Contents
   ========================================================================== */
@layer components {
  .toc {
    @apply m-8;

    @screen md {
      @apply text-lg;
    }

    /* ======================================================================
       Element
       ====================================================================== */
    &__anchor {
      @apply list-none cursor-pointer;

      &::-webkit-details-marker {
        @apply hidden;
      }

      &::before {
        @apply absolute left-0 mr-1 font-bold;
        content: "+";
      }

      &:hover {
        @apply underline;
      }
    }

    &__headline {
      @apply font-bold;
    }

    &__summary {
      @apply list-none cursor-pointer;

      &::-webkit-details-marker {
        @apply hidden;
      }

      &::before {
        @apply absolute left-0;
        content: "+";
      }

      &:hover {
        @apply underline;
      }
    }

    &__group {
      @apply relative block pb-4 pl-4 mb-4 border-b border-gray-600;
    }

    &__children {
      @apply pl-0 font-light text-gray-500;
    }

    &__item {
      @apply mt-1;

      .toc__anchor {
        &,
        &::before {
          @apply ml-4;
        }
      }
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
    [open] > .toc__summary,
    [open] > .toc__anchor {
      &::before {
        content: "-";
      }
    }

    [data-theme="dark-blue"] {
      .toc {
        &__headline,
        &__item::before,
        &__anchor::before {
          @apply text-blue-800;
        }
      }
    }

    [data-theme="light-green"] {
      .toc {
        &__headline,
        &__item::before,
        &__anchor::before {
          @apply text-green-300;
        }
      }
    }

    [data-theme="light-blue"] {
      .toc {
        &__headline,
        &__item::before,
        &__anchor::before {
          @apply text-blue-300;
        }
      }
    }

    [data-theme="red"] {
      .toc {
        &__headline,
        &__item::before,
        &__anchor::before {
          @apply text-red-500;
        }
      }
    }

    [data-theme="gray"] {
      .toc {
        &__headline,
        &__item::before,
        &__anchor::before {
          @apply text-gray-500;
        }
      }
    }
  }
}
