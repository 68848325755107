@layer base {
  html {
    scroll-behavior: smooth;
  }

  body {
    @apply mx-auto font-sans antialiased;
    max-width: 1280px;

    &::after {
      @apply fixed top-0 left-0;
      @apply invisible w-screen h-full opacity-100 pointer-events-none;
      @apply transition-all duration-200;
      content: "";
    }

    &.is-blurred {
      &::after {
        @apply visible bg-white opacity-75;
        content: "";

        @screen md {
          @apply invisible;
        }
      }
    }
  }

  a,
  summary,
  button {
    &:focus {
      @apply shadow-none outline-none;
    }

    &:focus-visible {
      @apply shadow-outline;
    }
  }

  b,
  strong {
    @apply font-medium;
  }

  sup {
    font-size: 50%;
    top: -0.75em;
  }

  .js-nested-link {
    @apply cursor-pointer;
  }
}
