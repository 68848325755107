/* ==========================================================================
   Social
   ========================================================================== */
@layer components {
  .social {
    @apply flex flex-wrap;

    /* ======================================================================
       Element
       ====================================================================== */
    &__item {
      @apply flex;
    }

    &__link {
      @apply flex items-center justify-center border-2 border-white rounded-full;
      @apply transition-colors duration-200;
      padding: 0.45rem;

      &:hover {
        @apply text-blue-500 bg-white;
      }
    }

    &__icon {
      width: 0.875rem;
      height: 0.875rem;
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
  }
}
