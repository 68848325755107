/* ==========================================================================
   Prose
   ========================================================================== */
@layer components {
  .prose {
    @apply relative text-lg font-light leading-snug text-gray-500;

    & + .prose,
    .annotated-asset + &,
    .callout-cards + & {
      @apply pt-8 border-t border-gray-300;

      &::before {
        @apply absolute top-0 left-0 w-full h-2 opacity-50;
        @apply bg-gradient-to-b to-white from-gray-300;
        content: "";
      }
    }

    /* ======================================================================
       Element
       ====================================================================== */
    a {
      &:hover {
        @apply underline;
      }
    }

    .lead {
      @apply text-2xl font-light leading-normal text-gray-400;

      a {
        @apply font-bold;
      }
    }

    .small {
      @apply text-sm;
    }

    p + p,
    ul + p,
    ol + p {
      @apply mt-4;
    }

    strong {
      @apply font-bold;
    }

    h1 {
      @apply mb-2 text-3xl font-medium text-blue-500;

      @screen md {
        @apply text-4xl;
      }
    }

    h2 {
      @apply mb-2 text-2xl font-medium text-blue-500;

      @screen md {
        @apply text-3xl;
      }
    }

    h3 {
      @apply mb-4 text-2xl font-light text-blue-500;

      @screen md {
        font-size: 1.75rem;
      }
    }

    h4 {
      @apply text-xl font-medium leading-none text-red-500;

      @screen md {
        @apply text-2xl;
      }
    }

    a {
      @apply font-medium text-blue-500;
      @apply transition-colors duration-200;

      &:hover {
        @apply text-blue-800 underline;
      }
    }

    * + ul,
    * + ol {
      @apply mt-4;
    }

    ul,
    ol {
      li + li {
        @apply mt-2;
      }

      ul,
      ol {
        @apply mt-0;
      }

      ul li + li {
        @apply mt-0;
      }

      img {
        @apply inline-block;
      }
    }

    ul {
      @apply pl-2;
      list-style-type: "\2022";

      li {
        @apply pl-4;
      }
    }

    ol {
      @apply pl-4 list-decimal;

      li {
        @apply pl-2;
      }
    }

    /* ======================================================================
       Theme Overrides
       ====================================================================== */
    [data-theme="dark-blue"] &,
    [data-theme="dark-blue"]& {
      h1,
      h2,
      h3,
      h5,
      h6,
      a,
      ul li::marker,
      .theme {
        @apply text-blue-800;
      }

      .gt {
        @apply text-blue-500;
      }
    }

    [data-theme="light-blue"] &,
    [data-theme="light-blue"]& {
      h1,
      h2,
      h3,
      h5,
      h6,
      a,
      ul li::marker,
      .theme {
        @apply text-blue-300;
      }

      .gt {
        @apply text-blue-500;
      }
    }

    [data-theme="light-green"] &,
    [data-theme="light-green"]& {
      h1,
      h2 {
        @apply text-green-300;
      }

      h3,
      h5,
      h6,
      a,
      ul li::marker,
      .theme {
        @apply text-green-400;
      }

      .gt {
        @apply text-blue-500;
      }
    }

    [data-theme="gray"] &,
    [data-theme="gray"]& {
      h1,
      h2,
      h3,
      h5,
      h6,
      a,
      ul li::marker,
      .theme {
        @apply text-gray-500;
      }
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
    &--section {
      @apply m-8;
    }

    &--has-aside {
      .prose {
        &__row {
          @apply flex flex-col -m-4;

          @screen md {
            @apply flex-row;
          }
        }

        &__col {
          @apply p-4;
        }

        &__aside {
          @apply my-4;

          @screen md {
            @apply mt-8 mb-0;
          }
        }
      }
    }

    &--mice {
      @apply leading-normal text-mice;

      [data-theme] & a {
        @apply font-light text-gray-500;
      }

      ul,
      ol {
        li {
          @apply pl-0;

          + li {
            @apply mt-0;
          }
        }
      }
    }
  }
}
