/* ==========================================================================
   Badges
   ========================================================================== */
@layer components {
  .badges {
    @apply flex flex-col border-t border-gray-300;

    @screen lg {
      @apply flex-row;
    }

    /* ======================================================================
       Element
       ====================================================================== */
    &__item {
      @apply flex-1 px-5 pt-5 pb-12;
      @apply text-lg text-right font-light leading-snug relative;

      @screen md {
        @apply text-3xl;
        min-height: 190px;

        [lang="fr-CA"] & {
          @apply text-2xl;
        }
      }

      &:hover {
        .badges {
          &__headline {
            @apply underline;
          }
        }
      }
    }

    &__headline {
      @apply font-bold text-blue-500 relative;
    }

    &__description {
      @apply ml-auto mr-0 relative;
      max-width: 20ch;

      [lang="fr-CA"] & {
        max-width: 23ch;
      }
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
    &__item {
      &--fill {
        @apply bg-blue-800 text-white;

        .badges {
          &__headline {
            @apply text-white;
          }
        }
      }
    }
  }
}
