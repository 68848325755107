/* ==========================================================================
   Numbered List
   ========================================================================== */
@layer components {
  .numbered-list {
    @apply mx-8 -my-4 text-lg font-light leading-snug text-gray-500;

    /* ======================================================================
       Element
       ====================================================================== */
    &__item {
      @apply flex mb-4;
    }

    &__number {
      @apply flex-shrink-0 mr-4;
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
  }
}
