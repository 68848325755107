/* ==========================================================================
   Header
   ========================================================================== */
@layer components {
  .header {
    /* ======================================================================
       Element
       ====================================================================== */
    &__logo {
      @apply w-full;
      width: 365px;
      height: 38.5px;
    }

    &__top {
      @apply flex items-center justify-between px-8 py-6 -my-2;

      @screen md {
        @apply py-10;
      }
    }

    &__top-col {
      @apply flex-grow py-2;
    }

    &__switcher {
      @apply p-2;
    }

    &__toggle-nav {
      @apply w-10 p-2 text-gray-500;

      @screen md {
        @apply hidden;
      }
    }

    &__close {
      @apply absolute top-0 right-0 z-10 p-1 m-8 text-gray-500 w-7;
    }

    &__sites {
      @apply flex justify-end -m-1;
    }

    &__site {
      @apply flex p-1 text-xs leading-none text-gray-500;

      &:after {
        @apply ml-2;
        content: "|";
      }

      &:last-child:after {
        @apply hidden;
      }

      @screen md {
        @apply text-base;
      }
    }

    &__site-link {
      @apply transition-colors duration-200;

      &:hover {
        @apply text-blue-500 underline;
      }
    }

    &__bottom {
      @apply px-8 py-2 border-t border-b border-gray-300;

      @screen md {
        @apply py-4;
      }
    }

    &__links {
      @apply flex flex-wrap -mx-2 -my-1;

      @screen md {
        @apply -mx-6 justify-end;
      }
    }

    &__link-item {
      @apply px-2 py-1;

      @screen md {
        @apply px-6;
      }
    }

    &__link {
      @apply text-xs text-gray-500 transition-colors duration-200 font-medium;

      &:hover {
        @apply text-blue-500 underline;
      }

      @screen md {
        @apply text-base;
      }
    }

    &__mobile-nav {
      @apply fixed top-0 left-0 z-50 w-full pt-8 bg-white;
      @apply opacity-0 transform -translate-y-full;
      @apply transition-all duration-200;
    }

    /* =====================================================================
       Modifier
       ====================================================================== */
    &__top-col {
      &--right {
        @apply flex items-center justify-end -m-2;
        @apply transform translate-y-1;

        @screen md {
          @apply translate-y-2;
        }
      }
    }

    &__mobile-nav {
      &.is-open {
        @apply opacity-100 translate-y-0;

        @screen md {
          @apply opacity-0 -translate-y-full;
        }
      }
    }
  }
}
