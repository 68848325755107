/* ==========================================================================
   Layout
   ========================================================================== */
@layer components {
  .layout {
    @apply flex flex-col;

    @screen md {
      @apply flex-row;
    }

    /* ======================================================================
       Element
       ====================================================================== */
    &__sidebar {
      @apply relative hidden py-8 bg-gray-100 px-7;

      @screen md {
        @apply block w-1/4 pr-2;
        max-width: 300px;

        &::after {
          @apply absolute top-0 w-2 h-full opacity-50;
          @apply bg-gradient-to-r to-transparent from-gray-300;
          left: 100%;
          content: "";
        }
      }
    }

    &__main {
      @apply flex-grow;

      @screen md {
        @apply w-3/4;
      }
    }

    /* ======================================================================
       Modifier
       ====================================================================== */
  }
}
